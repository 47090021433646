import { useStaticQuery, graphql } from "gatsby"

export const useLatestArticle = () => {
  const { allNodeArticle } = useStaticQuery(
    graphql`
      query {
        allNodeArticle(limit: 1, sort: {fields: created, order: DESC}) {
          nodes {
            title
            drupal_id
            id
            path {
              alias
            }
            relationships {
              field_featured_image {
                field_media_image {
                  alt
                  title
                }
                relationships {
                  field_media_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allNodeArticle.nodes && allNodeArticle.nodes.length ? allNodeArticle.nodes[0] : {};
}