import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H2, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS} from "../../constants";

const OnePlace = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content>
          <Title className="animate-in-slide-up">One place.</Title>
          <Paragraph className="animate-in-slide-up">
            Taking care of business doesn’t have to mean hiring multiple companies. JL Tech has
            the knowledge and equipment to handle all your promotional and IT needs.
          </Paragraph>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(OnePlace);

const Wrapper = styled(Section)`
  background: rgba(65, 45, 219, 0.7);
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 45%;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    width: 30%;
  }
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;