import React from "react";
import styled from "styled-components";

const Underline = (props) => {
  return (
    <Background>
      <Bouncer className="animate-in-slide-left" {...props}></Bouncer>
    </Background>
  );
};

export default Underline;

const Background = styled.div`
  position: relative;
  height: 2px;
  width: 100%;
  background: #f1f0f5;
`;

const Bouncer = styled.div`
  position: absolute;
  background: #ec3917;
  height: 2px;
  width: 33%;
  bottom: 0;
  left: 0;
`;