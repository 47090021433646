import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../section";
import {BREAKPOINTS, PATHS} from "../../constants";
import {CONTAINER, H3, LEADTEXT} from "../../styles/Global";
import bg from "../../images/keyb.jpeg"
import ReadMore from "../ReadMore";

const About = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content>
          <Title className="animate-in-slide-up">Who We Are.</Title>
          <Paragraph className="animate-in-slide-up">
            JL Tech is a mix of talents from a variety of fields culminating
            in over 60 years of experience. We build brands through a
            combination of design, development, media and marketing services
            built to take your business to a new level.
          </Paragraph>
          <ReadMore to={PATHS.about} className="animate-in-slide-up">More About Us</ReadMore>
        </Content>
      </Container>
    </Wrapper>
  );
};
export default forwardRef(About);

const Wrapper = styled(Section)`
  background-color: #ffffff;
  position: relative;
  
  &&:before {    
    content: "";
    background-image: url("${bg}");
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.25;
  } 
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  margin: 0;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 45%;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    width: 30%;
  }
`;

const Title = styled.h3`
  ${H3}
  text-align: left;
  color: #1a1258;
  text-transform: capitalize;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: #1a1258;
`;