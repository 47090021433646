import React, {forwardRef, useState} from "react";
import {navigate} from "gatsby";
import styled from "styled-components";
import Section from "../section";
import MarketingImg from "../../images/marketing_hero.jpg";
import MarketingImg2x from "../../images/marketing_hero@2x.jpg";
import MediaImg from "../../images/media_hero.jpg";
import MediaImg2x from "../../images/media_hero@2x.jpg";
import ITImg from "../../images/it_hero.jpg";
import ITImg2x from "../../images/it_hero@2x.jpg";
import AerialImg from "../../images/aerial_hero.jpg";
import AerialImg2x from "../../images/aerial_hero@2x.jpg";
import {BREAKPOINTS, PATHS} from "../../constants";
import {CONTAINER, H4, LEADTEXT} from "../../styles/Global";
import ReadMore from "../ReadMore";

const Service = ({menu, ...props}, ref) => {
  const [active, setActive] = useState(false);
  const handleClick = (e, service) => {
    e.preventDefault();

    if (active === service) {
      navigate(PATHS[service])
    } else {
      setActive(service);
    }
  };

  const handleHover = (e, service) => {
    e.preventDefault();
    setActive(service);
  };

  let items = [
    {
      id: 'marketing',
      title: 'Marketing',
      path: PATHS.marketing,
      description: 'Promoting your business isn’t rocket science. JL Tech’s team of graphic artists and designers can execute your vision for marketing campaigns, both traditional and digital.'
    },
    {
      id: 'media',
      title: 'Media',
      path: PATHS.media,
      description: 'The sky is the limit for your media needs. Photography, videography, editing, writing – JL Tech has the equipment and knowledge to document all aspects of a project and bring it to life.'
    },
    {
      id: 'it',
      title: 'IT',
      path: PATHS.it,
      description: 'JL Tech’s experienced technicians know how to implement solutions to IT needs. Custom built computer systems and software, security systems, smart home installations, and more are available.'
    },
    {
      id: 'aerial',
      title: 'Aerial',
      path: PATHS.aerial,
      description: 'Sometimes, the best view is from above. JL Tech has FAA-certified pilots and multiple drone options for showcasing your work. From showing a product to touring a neighborhood, we have you covered.'
    }
  ]

  return (
    <Wrapper {...props} ref={ref}>
      <Container menu={menu}>
        <Content>
          {items.map((item, index) => {
            return (
              <Item
                key={index}
                className={active === item.id ? 'active' : ''}
                onClick={(e) => handleClick(e, item.id)}
                onMouseEnter={(e) => handleHover(e, item.id)}>
                <ItemTitle className={active === item.id ? 'active' : ''}>
                  <ItemTitleRotate>{item.title}</ItemTitleRotate>
                </ItemTitle>
                <ItemContent className={active === item.id ? 'active' : ''}>
                  <p>{item.description}</p>
                  <ReadMore to={item.path}>
                    Learn more
                  </ReadMore>
                </ItemContent>
              </Item>
            );
          })}
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(Service);


const Wrapper = styled(Section)`
  background: rgba(255, 255, 255, 0.7);
  overflow: hidden;
`;

const Container = styled.div`
  ${CONTAINER}
  height: 100%;
  padding: ${props => props.menu ? '0 20px' : '0'};
`;

const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: start;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  padding-top: 80px;
  padding-bottom: 200px;
  justify-content: start;
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    max-width: 800px;
    align-items: center;
    justify-content: center;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    max-width: 1600px;
    padding: 0px 100px 200px;
  }
`;

const Item = styled.div`
  min-width: 35px;
  display: grid;
  margin: 0 auto;
  position: relative;
  flex: 1;
  color: white;
  transition: 0.3s;
  height: 240px;
  width: 100px;
  background-color: rgba(34, 34, 34, 0.4);
 
  &&:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }
  
  &&:nth-child(1):before {
    background-image: url("${MarketingImg}");
  }
  
  &&:nth-child(2):before {
    background-image: url("${MediaImg}");
  }
  
  &&:nth-child(3):before {
    background-image: url("${ITImg}");
  }
  
  &&:nth-child(4):before {
    background-image: url("${AerialImg}");
  }
  
  &&.active {
    transition: 0.5s;
    flex-grow: 8;
    color: #fff;
    background-color: rgba(0, 0, 255, 0.3);
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {  
    &&:nth-child(1):before {
      background-image: url("${MarketingImg2x}");
    }
    
    &&:nth-child(2):before {
      background-image: url("${MediaImg2x}");
    }
    
    &&:nth-child(3):before {
      background-image: url("${ITImg2x}");
    }
    
    &&:nth-child(4):before {
      background-image: url("${AerialImg2x}");
    }
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    height: 440px;
    width: 400px;
  }
`;

const ItemContent = styled.div`
  display: none; 
  position: absolute;
  top: 240px;
  width: 100%;
  padding: 20px 40px 10px 40px;
  
  p {
    ${LEADTEXT}
    text-align: left;
    letter-spacing: 0px;
    color: #1a1258;
    opacity: 1;
  }
  
  &&.active {
    transform: none;
    display: block;
    transition: all 0.8s ease-in-out;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    padding: 20px 60px 10px 60px;
    
    p {
      min-width: 400px;
    }
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    top: 440px;
    padding: 40px 60px 10px 120px;
  }
`;

const ItemTitle = styled.div`
  position: absolute;
  top: 240px;
  writing-mode: vertical-rl;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    top: 440px;
  }
`;

const ItemTitleRotate = styled.span`
  ${H4}
  padding-top: 20px;
  font: normal normal bold 31px/51px var(--unnamed-font-family-brandon-grotesque);
  letter-spacing: 0px;
  color: #1a1258;
  text-transform: capitalize;
  opacity: 1;
  max-width: 35px;
  line-height: 35px;
  overflow: hidden;
  
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    overflow: visible;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    max-width: 100%;
    padding: 40px;
  }
`;