import React from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import {BUTTONTEXT} from "../styles/Global";
import {HiOutlineArrowCircleRight} from "@react-icons/all-files/hi/HiOutlineArrowCircleRight";

const ReadMore = ({children, animation, delay, easing, icon, textStyle, iconStyle, external, ...props}) => {
  return (
    <div
      data-sal={animation ? animation : null}
      data-sal-delay={delay ? delay : null}
      data-sal-easing={easing ? easing : null}>
      {external ? (
        <External to={'/'} {...props}>
          <MoreText style={textStyle}>{children}</MoreText>
          <MoreIcon style={iconStyle}>
            {icon ? icon : <HiOutlineArrowCircleRight />}
          </MoreIcon>
        </External>
      ) : (
        <Button to={'/'} {...props}>
          <MoreText style={textStyle}>{children}</MoreText>
          <MoreIcon style={iconStyle}>
            {icon ? icon : <HiOutlineArrowCircleRight />}
          </MoreIcon>
        </Button>
      )}
    </div>
  );
};

export default ReadMore;

const External = styled.a`
  display: block;
  position: relative;
  min-width: 150px;
`;

const Button = styled(Link)`
  display: block;
  position: relative;
  min-width: 150px;
`;

const MoreText = styled.span`
  ${BUTTONTEXT}
  letter-spacing: 0px;
  color: #ec3917;
  opacity: 1;
  line-height: 30px;
  letter-spacing: 0px;
`;

const MoreIcon = styled.span`
  ${BUTTONTEXT}
  color: #ff3d00;
  vertical-align: middle;
  padding-left: 6px;
`;
