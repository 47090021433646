import * as React from "react";
import Index from "../components/home/index";

const IndexPage = () => {
  return (
    <>
      <Index />
    </>
  );
};

export default IndexPage;
