import React from "react";
import Layout from "../Layout";
import Header from "../header/Header";
import About from "./About";
import Article from "./Article";
import OnePlace from "./OnePlace";
import Services from "./Services";
import styled from "styled-components";
import header4KImg from "../../images/header@2x.png";
import headerMobileImg from "../../images/header-mob.png";
import {BREAKPOINTS} from "../../constants";
import Project from "./Project";
import Contact from "../contact";

const Index = () => {
  return (
    <Layout underlay={<Underlay />} metatags={{
      title: "JLTech >WE DO IT ALL< Web, Marketing, Media, Aerial & Security",
      desc: `JL Tech is a mix of talents from a variety of fields culminating in over 60 years of experience. 
        We build brands through a combination of design, development, media and marketing services 
        built to take your business to a new level.`,
      keywords: `low voltage installers near me, low voltage installers spokane, low voltage installers coeur d'alene, app developers near me,
        security systems near me, security system installers spokane, security system installers coeur d'alene, website developers coeur d'alene,
        drupal developers coeur d'alene, react developers coeur d'alene, it services coeur d'alene, app developers coeur d'alene,
        website developers spokane, website designers spokane, website designers coeur d'alene, website developers near me, website designers near me,
        app developers spokane, drone services near me, drone services spokane, react developers spokane, drupal developers spokane, jltech, cda`
    }} snap>
      <Header />
      <OnePlace />
      <Services />
      <Project />
      <About />
      <Article />
      <Chat />
    </Layout>
  );
};

export default Index;

const Underlay = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-color: transparent;
  background-image: url("${headerMobileImg}");
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    background-image: url("${header4KImg}");
  }
`;

const Chat = styled(Contact)`
  height: auto;
  padding: 100px 0;
`;