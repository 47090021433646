import { useStaticQuery, graphql } from "gatsby"

export const useLatestProject = () => {
  const { allNodeProject } = useStaticQuery(
    graphql`
      query {
        allNodeProject(limit: 1, sort: {fields: created, order: DESC}, filter: {promote: {eq: true}}) {
          nodes {
            title
            drupal_id
            id
            path {
              alias
            }
            relationships {
              field_featured_image {
                field_media_image {
                  alt
                  title
                }
                relationships {
                  field_media_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return allNodeProject.nodes && allNodeProject.nodes.length ? allNodeProject.nodes[0] : null;
}